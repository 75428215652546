export default {
  apiKey: "AIzaSyAw5dhlmcDW1BjZVCkx-vaRYYTg49BxGIE",
  authDomain: "le-pass-nc.firebaseapp.com",
  databaseURL: "https://le-pass-nc.firebaseio.com",
  projectId: "le-pass-nc",
  storageBucket: "le-pass-nc.appspot.com",
  messagingSenderId: "722586568481",
  appId: "1:722586568481:web:8eb8ad9ab099ecdd92a060",
  measurementId: "G-9Y0C7E88GM"
};
